/* START: PROFILE
=================== */

.profile-list > li {
  background: #fff;
  box-shadow: 0 0 5px #e4e5e8;
  border-radius: 3px;
  border: 2px solid #e4e5e8;
  min-height: 430px;
}
.profile-list .carousel-inner {
  border: 7px solid #e7e5e5;
  border-bottom-width: 12px;
  background: #fbfbfb;
  border-radius: 5px;
}
.profile-list > li {
  display: table;
  width: 100%;
  margin-bottom: 20px;
}
.profile-list > li > .col-md-3 {
  display: table-cell;
  float: none;
  padding: 20px;
  border-right: 1px solid #ccc;
}
.profile-list > li > .col-md-9 {
  display: table-cell;
  float: none;
  vertical-align: top;
}
.profile-list > li > .col-md-9 h2 {
  margin-bottom: 20px;
}
.profile-list > li > .col-md-9 ul {
  border: 1px solid #ccc;
  border-radius: 3px;
  margin: 50px 15px 0 15px;
  display: flex;
}
.profile-list > li > .col-md-9 ul li {
  text-align: center;
  padding: 20px 0;
}
.profile-list > li > .col-md-9 ul li strong {
  font-size: 16px;
  margin-bottom: 10px;
  color: #333;
}
.profile-list > li > .col-md-9 ul li p {
  margin: 0;
  font-size: 14px;
  color: #777;
}
/* .profile-list > li > .col-md-9 ul.first-list li {
    width:20%;
} 
.profile-list > li > .col-md-9 > .clearfix {
    padding-bottom: 60px;
    border-bottom: 1px solid #ccc;
}
*/
.profile-list > li > .col-md-9 > .pull-right {
  padding: 20px 0;
}
.profile-list .carousel .carousel-indicators {
  bottom: 14px;
  margin-left: -5%;
  width: auto;
}
.carousel-indicators-numbers li {
  text-indent: 0;
  margin: 0 2px;
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 100%;
  line-height: 30px;
  color: #fff;
  background-color: #999;
  transition: all 0.25s ease;
  display: none;
}
.carousel-indicators-numbers li.active {
  margin: 0 2px;
  width: 30px;
  height: 30px;
  background-color: transparent;
  display: block;
}
.profile-list .carousel .item figure {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 340px;
  min-width: 265px;
}
.profile-list .carousel-control {
  background: none;
  line-height: 38px;
  opacity: 1;
  left: 20%;
}
.profile-list .carousel-control.right {
  right: 20%;
  left: auto;
}
.profile-list .carousel-control span {
  color: #fff;
  text-shadow: none;
}
.profile-list .carousel-nav {
  background: #7e7d7d;
  position: absolute;
  bottom: 12px;
  min-height: 34px;
  width: 100%;
  border-left: 7px solid #e7e5e5;
  border-right: 7px solid #e7e5e5;
}
.shortlist {
  background: #f5f5f5;
  font-size: 18px;
  color: #333;
  border: 1px solid #d3d3d3;
  border-radius: 3px;
  padding: 5px 15px;
  margin: 0 10px;
}
.shortlist:hover,
.shortlist:focus,
.shortlist:active {
  color: #333;
}
.shortlist .glyphicon {
  color: #cb202d;
  margin-right: 5px;
}
.custom-btn {
  background: #cb202d;
  color: #fff;
  display: inline-block;
  padding: 5px 15px;
  border-radius: 3px;
  text-transform: capitalize;
  font-size: 18px;
}
.custom-btn:hover,
.custom-btn:focus,
.custom-btn:active {
  color: #fff;
}
/* END: PROFILE
================= */

/* START: PROFILE DETAIL
========================= */
.profile-detail {
  background: #fff;
  box-shadow: 1px 1px 5px #e4e5e8;
  border-radius: 3px;
  border: 1px solid #e4e5e8;
  padding: 10px;
}
.profile-detail h4 {
  font-size: 20px;
  border-bottom: 1px solid #cecece;
  text-transform: uppercase;
  padding-bottom: 15px;
  margin-bottom: 20px;
}
.profile-detail > ul {
  margin: 0;
  padding: 0;
}
.profile-detail > ul > li > .clearfix {
  border: 1px solid #e4e5e8;
  box-shadow: -1px 2px 5px #e4e5e8;
  margin: 10px;
  position: relative;
  min-height: 450px;
}
.profile-detail > ul > li > .clearfix .detail-header figure {
  width: 62px;
  height: 48px;
  position: absolute;
  top: 0;
  left: 0;
}
.green {
  background: #6fba23;
}
.orange {
  background: #fd7e28;
}
.red {
  background: #e0413f;
}
.purple {
  background: #963195;
}

.profile-detail > ul > li > .clearfix .detail-header figure [class*="icon-"] {
  background: url("../images/base-sprite.png") no-repeat scroll -62px -32px;
  content: "";
  height: 35px;
  left: 16px;
  position: absolute;
  top: 10px;
  width: 35px;
}
.profile-detail > ul > li > .clearfix .detail-header figure .icon-view {
  background-position: -5px -70px;
}
.profile-detail > ul > li > .clearfix .detail-header figure .icon-degree {
  background-position: -51px -71px;
}
.profile-detail > ul > li > .clearfix .detail-header figure .icon-award {
  background-position: -107px -72px;
}
.profile-detail > ul > li > .clearfix .detail-header h6 {
  font-size: 18px;
  text-transform: uppercase;
  padding: 14px 0 14px 100px;
  border-bottom: 1px solid #e4e5e8;
  margin: 0;
}
.profile-detail > ul > li > .clearfix .detail-body {
  padding: 20px 30px;
}
.profile-detail > ul > li > .clearfix .detail-body li {
  padding: 5px 0;
}
.profile-detail > ul > li > .clearfix .detail-body span:first-child {
  font-size: 14px;
  color: #777;
  display: inline-block;
  min-width: 150px;
  position: relative;
}
.profile-detail > ul > li > .clearfix .detail-body span:first-child:after {
  position: absolute;
  content: ":";
  right: 0;
  top: 0;
  width: 10px;
}
.profile-detail > ul > li > .clearfix .detail-body span:last-child {
  color: #333;
  font-size: 16px;
  font-weight: 600;
}
/* END: PROFILE DETAIL
========================= */

/* START: RESPONSIVE
===================== */

/* START: PROFILE
================== */
@media (max-width: 1199px) {
  .profile-list > li > .col-md-9 ul li strong {
    font-size: 14px;
  }
  .profile-list > li > .col-md-9 ul li p {
    font-size: 12px;
  }
}
/* END: PROFILE
================== */

/* START: PROFILE
================== */
@media (max-width: 991px) {
  .profile-list > li > .col-md-9 h2 {
    font-size: 24px;
  }
  .profile-list > li > .col-md-9 ul {
    display: inherit;
    margin: 25px 0 0 0;
  }
  .profile-list > li > .col-md-9 ul li {
    text-align: left;
    display: block;
    padding: 5px 10px;
    width: 100%;
  }
  .profile-list > li > .col-md-9 > .clearfix {
    padding-bottom: 20px;
  }
  /* END: PROFILE
================== */

  /* START: PROFILE
================== */
  .profile-list > li > .col-md-9 h2 {
    font-size: 24px;
  }
  .profile-list > li > .col-md-9 ul {
    display: inherit;
  }
  .profile-list > li > .col-md-9 ul li {
    text-align: left;
    display: block;
    padding: 5px 10px;
    width: 100%;
  }
  .profile-list > li > .col-md-9 > .clearfix {
    padding-bottom: 20px;
  }
  /* END: PROFILE
================== */

  /* START: PROFILE DETAIL
========================= */
  .profile-detail > ul,
  .profile-detail > ul > li {
    padding: 0;
  }
  .profile-detail > ul > li > .clearfix .detail-body {
    padding: 10px;
  }
}
/* END: PROFILE DETAIL
======================= */

/* START: PROFILE
================== */
@media (max-width: 767px) {
  .profile-list > li > .col-md-3,
  .profile-list > li > .col-md-9 {
    display: inline-block;
    border: none;
    width: 100%;
  }
  .profile-page footer {
    margin-bottom: 0;
  }
  .profile-list > li > .col-md-3 {
    padding: 10px;
  }
  .profile-list > li {
    display: inline-block;
    width: 100%;
    margin: 0 0 20px 0px;
  }
  .custom-btn,
  .shortlist {
    font-size: 14px;
  }
  /* END: PROFILE
================== */

  /* START: PROFILE DETAIL
========================= */
  .profile-detail > ul {
    padding: 0;
  }
  .profile-detail > ul > li > .clearfix {
    margin: 10px 0;
    min-height: auto;
  }
  .profile-detail > ul > li > .clearfix .detail-header h6 {
    font-size: 12px;
    padding: 18px 0 17px 70px;
  }
  .profile-detail > ul > li > .clearfix .detail-body {
    padding: 10px;
  }
  .profile-detail > ul > li > .clearfix .detail-body span:first-child {
    min-width: 110px;
    font-size: 14px;
  }
  .profile-detail > ul > li > .clearfix .detail-body span:last-child {
    font-size: 14px;
  }
  .profile-detail > ul > li {
    padding: 0;
  }
  /* END: PROFILE DETAIL
======================= */
}
