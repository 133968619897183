/* Checkbox and radio button style */
.radio label,
.checkbox label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  margin: 5px 0;
  padding-left: 25px;
}

input[type="radio"].disabled + label,
input[type="radio"].disabled:checked + label,
input[type="checkbox"].disabled + label,
input[type="checkbox"].disabled:checked + label {
  opacity: 0.5;
}

input[type="checkbox"],
input[type="radio"] {
  display: none;
}

.radio label:before,
.checkbox label:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0px;
  top: 0;
  bottom: 0;
  margin: auto;
  background: url(../images/base-sprite.png) 0 0 no-repeat;
}

.radio label:before {
  background-position: -5px -5px;
}

.checkbox label:before {
  background-position: -5px -35px;
}

input[type="radio"]:checked + label:before {
  background-position: -35px -5px;
}

input[type="radio"].disabled + label:before {
  background-position: -130px -50px;
}

input[type="radio"].disabled:checked + label:before {
  background-position: -170px -50px;
}

input[type="checkbox"]:checked + label:before {
  background-position: -65px -5px;
}

input[type="checkbox"].disabled + label:before {
  background-position: -96px -35px;
}

input[type="checkbox"].disabled:checked + label:before {
  background-position: -125px -35px;
}

.ie8 input[type="checkbox"],
.ie8 input[type="radio"] {
  display: inline-block;
}

.ie8 .radio label:before,
.ie8 .checkbox label:before {
  display: none;
}

.ie8 .radio label,
.ie8 .checkbox label {
  padding-left: 0;
}

/* START: RESPONSIVE
===================== */
@media (max-width: 767px) {
  html,
  body {
    height: 100%;
  }
  .pos-fixed {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 99;
    box-shadow: 0 0 3px #484848;
  }
  .pos-fixed a {
    background: #eee;
    position: relative;
    display: block;
    color: #333;
    padding: 8px 0;
    font-weight: 600;
  }
  .pos-fixed a:before {
    background: url("../images/base-sprite.png") no-repeat scroll -36px -35px;
    content: "";
    height: 20px;
    left: 117px;
    position: absolute;
    top: 8px;
    width: 20px;
  }
}
