.subscription-summary {
  border-radius: 4px;
  background-color: #ffffff;
  padding: 35px 0 25px;
  text-align: center; }

.subscription-summary .row {
  margin: 0; }

.subscription-summary .item {
  padding-left: 2vh; }

.subscription-summary .item .title {
  height: 14px;
  font-size: 12px;
  letter-spacing: 1px;
  color: #707780;
  margin: 0 0 10px; }

.subscription-summary .value {
  height: 28px;
  font-size: 24px;
  margin-bottom: 8px;
  color: #3f5166; }
