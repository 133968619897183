/** Less variables **/
/* Font weights */
/* Font colors */
/* Background colors */
/* Grays */
/* Other colors */
.border {
    border: 1px solid #d1d1d1;
}
.border-radius {
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
}
/* Media query sizes */
/* Only phone */
/* Phone and tablet */
/* Only laptop */
/* Laptop and desktop */
/* Only desktop */
/** Overridden bootstrap styles **/
@media screen and (min-width: 1201px) {
    .container {
        max-width: 1080px;
    }
    .container-full {
        width: 100%;
    }
}
@media screen and (min-width: 992px) {
    .container {
        max-width: 1080px;
        width: 100%;
    }
}
p {
    margin: 0 0 14px;
}
a {
    color: #0090cc;
}
a.text-link {
    color: #4a5056;
    text-decoration: none;
}
b,
strong {
    font-weight: 500;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    color: #40454a;
    font-weight: 500;
}
.progress {
    margin: 14px 0 20px 0;
}
.third {
    width: 33%;
    display: inline-block;
}
/** Headings **/
h1 {
    font-size: 22px;
}
h2 {
    font-size: 22px;
}
h3 {
    font-size: 20px;
}
h4 {
    font-size: 18px;
}
h5 {
    font-size: 16px;
}
h6 {
    font-size: 14px;
}
@media screen and (max-width: 767px) {
    h1 {
        font-size: 20px;
    }
    h2 {
        font-size: 18px;
    }
    h3 {
        font-size: 16px;
    }
    h4 {
        font-size: 15px;
    }
    h5 {
        font-size: 14px;
    }
    h6 {
        font-size: 13px;
    }
}
/** Globals **/
label {
    color: #555a61;
    font-weight: 500;
}
.checkbox label {
    color: #4a5056;
}
.container-wrapper {
    max-width: 1080px;
}
.sheet {
    background-color: #fff;
    border: 1px solid #ededed;
    padding: 4px 30px 14px;
}
form .checkbox,
form .radio {
    line-height: 21px !important;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
}
form .col-3 {
    display: inline-block;
    width: 33%;
}
.related-selects .first-select {
    padding-right: 2px;
}
.related-selects .last-select {
    padding-left: 2px;
}
.fa {
    color: #414654;
}
.fa-2x {
    font-size: 2.5em;
}
.invisible {
    visibility: hidden;
}
.list-none {
    list-style: none;
    padding: 0 !important;
}
.list-none li {
    display: inline-block;
    margin-right: 5px;
}
.short-paragraph p {
    margin: 0 0 2px;
}
.popover {
    background: #414654;
    color: #dde0e2;
}
.popover.right > .arrow:after {
    border-right-color: #414654 !important;
}
.badge {
    background-color: #f74a58;
    font-size: 10px;
    line-height: 15px;
    padding: 1px 7px;
    position: absolute;
    right: -5px;
    top: 5px;
    z-index: 1;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
}
.navbar-collapse.collapsing .badge,
.navbar-collapse.in .badge {
    position: initial;
}
.navbar-default .navbar-toggle .icon-bar {
    background-color: #fff;
}
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
    background-color: transparent;
}
.navbar-default .navbar-text {
    color: #ffffff;
}
.btn-group .badge {
    background: #414654;
    vertical-align: middle;
}
img.logo {
    height: 18px;
}
.btn-group.wide {
    width: 100%;
}
.btn-group.wide .btn {
    width: 50%;
}
.static {
    position: static;
}
.highlight {
    background: #bbf1dd;
}
p.highlight {
    display: inline-block;
}
.nowrap {
    white-space: nowrap;
}
.no-bullet-ul li {
    list-style-type: none;
}
.cursor-pointer {
    cursor: pointer;
}
.link-red {
    color: #8a8a8a;
}
.marb10 {
    margin-bottom: 10px;
}
.px-10 {
    padding: 0 10px;
}
@media screen and (min-width: 992px) {
    .left-column {
        border-right: 1px solid #ededed;
    }
    .right-column {
        float: right;
    }
    .right-column-inner {
        padding-left: 0;
    }
}
@media screen and (max-width: 991px) {
    .nowrap {
        white-space: initial;
    }
}
@media screen and (max-width: 767px) {
    .tooltip {
        display: none !important;
    }
    form .col-3 {
        display: inline-block;
        width: 100%;
    }
}

/** Profile Image **/
.profile-image .thumbnail {
    background-color: transparent;
    border-radius: 4px;
    height: 128px;
    overflow: hidden;
    padding: 0;
    position: relative;
    text-align: center;
    width: 128px;
}
.profile-image .thumbnail > img {
    display: inline-block;
    max-height: 128px;
    max-width: 128px;
    vertical-align: middle;
    font-size: 12.5px;
    color: #4a5056;
}
.profile-image .thumbnail .thumbnail-helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}
@media print {
    .profile-image .thumbnail > img {
        height: 120px;
        width: 120px;
    }
    .profile-image.nophoto {
        width: 0 !important;
        padding-right: 30px !important;
    }
}
@media screen and (max-width: 767px) {
    .profile-image .thumbnail,
    .profile-image .thumbnail > img {
        max-height: 80px;
        max-width: 80px;
    }
}
/** Sticky Footer **/
html,
body {
    height: 100%;
}
#wrap {
    min-height: 100%;
    height: auto !important;
    height: 100%;
    /* Negative indent footer by it's height+margin */
    margin-top: 30px;
    margin-right: auto;
    margin-bottom: -131px;
    margin-left: auto;
}
footer ul.list-inline {
    list-style: none;
}
footer ul.list-inline li {
    display: inline;
    padding: 0 10px;
    border-left: solid 1px #886b65;
}
footer ul.list-inline li:first-child {
    border-left: none;
}

/** Body **/
body {
    background-color: #f6f7f9;
    color: #4a5056;
    font-size: 12.5px;
    font-family: 'Lato', sans-serif;
    line-height: 1.8;
    padding-top: 46px;
    /* To account for fixed header.
    In case of special offer being shown, JS is used to change this value. */
}
body.noscroll {
    overflow: hidden;
}
/** Navigation and Footer **/
#navigation,
#footer {
    background: #34495e;
    border: 0;
    border-radius: 0;
}
#navigation a,
#footer a {
    color: #ffffff;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
}
#navigation a.special,
#footer a.special {
    color: #1fb799;
}
#navigation a.active,
#footer a.active {
    color: white;
}
/** Header, Footer **/
#navigation.navbar {
    background-color: #cb202d;
    min-height: 46px;
    margin-bottom: 28px;
    z-index: 1090;
}
#navigation.navbar.home-nav {
    background-color: transparent;
    position: absolute;
}
.home-nav .navbar-collapse > ul:last-child > li:not(:last-child)::after {
    position: absolute;
    content: '|';
    top: 12px;
    right: -2px;
    color: #fff;
}
#navigation .navbar-brand {
    height: 46px;
}
#navigation .navbar-nav > li > a {
    padding-bottom: 13px;
    padding-top: 13px;
}
#navigation .navbar-nav > li:hover a {
    color: white;
    background: transparent !important;
}
#navigation .navbar-toggle {
    border: none !important;
}
#navigation #nav-invite-friends span {
    background: #1fb799;
    padding-bottom: 2px;
    border-radius: 3px;
    padding: 4px 8px;
    color: white;
}
#navigation #nav-invite-friends:hover span {
    background: #16927a;
}
#navigation .special-offer {
    background: #1fb799;
    text-align: center;
    padding: 2px 0;
    font-size: 14px;
    color: white;
}
#navigation .navbar-collapse.in {
    background-color: #000;
    border: 0;
}
#navigation .navbar-collapse {
    max-height: 400px;
}
/* Home page banner */
.jumbotron {
    position: relative;
    background: url(../images/template-banner.jpg) center center no-repeat;
    background-size: cover;
    background-color: #000;
    padding: 14rem 0 14rem;
    margin: 0;
    top: -46px !important;
}
.jumbotron h2 {
    font-size: 3rem;
    margin: 1rem 0;
    color: #fff;
}

@media screen and (max-width: 767px) {
    .jumbotron {
        background-position: -500px;
    }
    .jumbotron h2 {
        font-size: 2.5rem;
    }
    #navigation .navbar-collapse {
        background-color: #87343b;
    }
}

.jumbotron-content {
    max-width: 1080px;
    text-align: left;
    position: relative;
}

.jumbotron .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* background-color: #00000054; */
}

.cta-btn,
.cta-btn:hover,
.cta-btn:active,
.cta-btn:focus {
    background-color: #00000080;
    border: 1px solid #fdf854;
    color: #fff;
    font-size: 1.6rem !important;
}

/* Home page content */
.section-header {
    font-size: 3.25rem;
    font-weight: 600;
    margin-bottom: 3rem;
}

.highlight-color {
    color: #cb202d;
}

/** Footer specific **/
#footer {
    background: none;
    padding: 30px 0;
}
#footer a {
    color: #8a8a8a;
}
#footer .nav > li {
    display: inline-block;
}
#footer .navbar-nav > li:hover a {
    color: #444444;
}
#footer #footer-contact-us {
    cursor: pointer;
}
#footer .copyright {
    cursor: text;
}
#footer .copyright:hover {
    color: #8a8a8a !important;
}
#footer .social-icon {
    border-color: #8a8a8a;
    border-radius: 50%;
    border-style: solid;
    border-width: 1px;
    display: block;
    font-size: 16px;
    height: 36px;
    margin-right: 10px;
    margin-top: 7px;
    text-align: center;
    width: 36px;
}
#footer .social-icon i {
    color: inherit;
    left: 12px;
    position: absolute;
    top: 10px;
}
#footer .navbar {
    padding: 0 60px;
}
#footer .footer-block {
    margin-bottom: 20px;
    width: 20%;
    float: left;
}
#footer .footer-block:nth-child(2) {
    width: 25%;
}
#footer .footer-block:last-child {
    width: 15%;
}
#footer .footer-block .footer-block-wrap {
    padding-right: 10px;
}
#footer .footer-block .footer-block-wrap .footer-block-heading {
    margin-top: 0;
    font-size: 12.5px;
    padding-bottom: 2px;
    display: inline-block;
    text-transform: none;
    letter-spacing: 0;
    font-weight: 500;
    margin-bottom: 5px;
    color: #555;
}
#footer .footer-block .footer-block-wrap .footer-block-content li {
    display: block;
}
#footer .footer-block .footer-block-wrap .footer-block-content li a {
    text-transform: none !important;
    letter-spacing: 0 !important;
    font-size: 12.5px !important;
    font-weight: 400 !important;
}
#footer
    .footer-block
    .footer-block-wrap
    .footer-block-content
    .footer-social-links {
    margin-top: 8px;
}
#footer
    .footer-block
    .footer-block-wrap
    .footer-block-content
    .footer-social-links
    .fa {
    font-size: 24px;
    margin-right: 4px;
    color: #8a8a8a;
}
@media screen and (max-width: 767px) {
    #footer {
        padding: 10px;
    }
    #footer .navbar {
        padding: 0;
    }
    #footer .navbar-nav {
        text-align: center;
    }
}
@media screen and (max-width: 991px) {
    #footer .navbar-right,
    #footer .navbar-left {
        text-align: center;
        float: none !important;
    }
    #footer .navbar-nav > li {
        float: none;
    }
}
@media screen and (max-width: 991px) {
    #footer .navbar {
        padding: 20px 0 0 0 !important;
    }
}
@media screen and (max-width: 767px) {
    #footer .footer-block {
        width: 50% !important;
    }
}

/** Forms **/
.form-control {
    border-radius: 4px;
    font-size: 12.5px;
    height: 38px;
    padding: 8px 12px;
}
.form-control:focus {
    border-color: #0090cc;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 2px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 2px rgba(102, 175, 233, 0.6);
}
.form-group button[type='submit'] {
    margin-top: 6px;
}
.form-error {
    display: block;
    color: #f74a58;
    margin: 10px 0;
}
.form-inline .form-group {
    margin-bottom: 0;
}
.form-inline.wide .form-group {
    display: inline-block;
    margin-right: 2%;
    vertical-align: top;
    width: 70% !important;
}
.form-inline.wide .form-group input {
    width: 100%;
}
.form-inline.wide button {
    width: 26%;
    float: right;
    border-bottom: 3px solid #ccc;
}
/** Button **/
.btn {
    font-size: 12.5px;
}
.btn.active,
.btn:active {
    box-shadow: none;
}
.btn-lg {
    border-radius: 4px;
    font-size: 14px;
}
.btn.save {
    margin-right: 10px;
}
.btn-square {
    font-size: 36px;
    width: 55px;
    height: 55px;
    margin-top: 10px;
    margin-left: 100px;
    padding: 0;
}
.btn-wide {
    width: 100% !important;
}
.btn-third {
    margin-right: 3%;
    width: 30% !important;
}
.btn-third:last-child {
    margin-right: 0;
}
.btn-twitter {
    background: #5da6f0;
}
.btn-facebook {
    background: #485da8;
}
.btn-linked {
    background: #007bb6;
}
.btn-twitter,
.btn-facebook,
.btn-linked {
    color: #ffffff;
}
.btn-twitter i,
.btn-facebook i,
.btn-linked i {
    color: #ffffff;
    padding-right: 3px;
}
.btn-twitter:hover,
.btn-facebook:hover,
.btn-linked:hover {
    color: #ffffff;
    opacity: 0.95;
}
.btn-danger {
    background-color: #f74a58;
    border: 0;
    border-bottom: 3px solid #d43441;
}
.btn-danger.btn-shadow {
    border-bottom: 4px solid #d43441;
}
.btn-danger.active,
.btn-danger.focus,
.btn-danger:active,
.btn-danger:focus,
.btn-danger:hover,
.open > .dropdown-toggle.btn-danger {
    background-color: #da3f4c;
    border-color: #b92e3a;
}
.btn-primary {
    background-color: #1fc055;
    border: 0;
    border-bottom: 3px solid #0f9f3f;
}
.btn-primary.active,
.btn-primary.focus,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary {
    background-color: #14b34a;
    border-color: #0f9f3f;
}

.btn-warning,
.btn-warning.disabled,
.btn-warning[disabled] {
    background-color: #ec8a52;
    border: 0;
    border-bottom: 3px solid #df6627;
}
.btn-warning.active,
.btn-warning.focus,
.btn-warning:active,
.btn-warning:focus,
.btn-warning:hover,
.open > .dropdown-toggle.btn-warning {
    background-color: #d8763e;
    border-color: #b9602d;
}
.btn-success,
.btn-success.disabled,
.btn-success[disabled],
.btn-success.disabled.focus,
.btn-success.disabled:focus,
.btn-success.disabled:hover,
.btn-success[disabled].focus,
.btn-success[disabled]:focus,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success.focus,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success:hover {
    background-color: #cb202d;
    border: 0;
    border-bottom: 3px solid #cb202d;
}
.btn-success.active,
.btn-success.focus,
.btn-success:active,
.btn-success:focus,
.btn-success:hover,
.open > .dropdown-toggle.btn-success,
.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success:active.focus,
.btn-success:active:focus,
.btn-success:active:hover,
.open > .dropdown-toggle.btn-success.focus,
.open > .dropdown-toggle.btn-success:focus,
.open > .dropdown-toggle.btn-success:hover {
    background-color: #cb202d;
    border-color: #cb202d;
}
.btn:focus,
.btn:active:focus,
.btn.active:focus {
    outline: none !important;
}

/* Candidate Onboarding */
#candidate-onboarding .heading {
    border-bottom: 1px solid #ededed;
    padding-bottom: 16px;
}
#candidate-onboarding .heading p {
    margin-bottom: 0;
}
#candidate-onboarding .candidate-onboard-step {
    animation: todownAnimate 175ms ease;
    -moz-animation: todownAnimate 175ms ease;
    -webkit-animation: todownAnimate 175ms ease;
}
#candidate-onboarding .candidate-onboard-step.ng-hide.ng-hide-add-active {
    display: none !important;
}
#candidate-onboarding .candidate-onboard-step .onboard-candidate-photo {
    padding-bottom: 20px;
}
#candidate-onboarding .candidate-onboard-step .onboard-candidate-photo > .row {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
}
#candidate-onboarding
    .candidate-onboard-step
    .onboard-candidate-photo
    .profile-image {
    text-align: left;
    max-width: 100px;
    line-height: 1;
}
#candidate-onboarding
    .candidate-onboard-step
    .onboard-candidate-photo
    .thumbnail {
    display: inline-block;
    max-width: 68px;
    max-height: 68px;
}
#candidate-onboarding
    .candidate-onboard-step
    .onboard-candidate-photo
    .thumbnail
    img {
    max-width: 68px;
    max-height: 68px;
    border-radius: 3px;
}
#candidate-onboarding
    .candidate-onboard-step
    .onboard-candidate-photo
    .onboard-photo-label {
    padding-left: 0;
}
#candidate-onboarding
    .candidate-onboard-step
    .onboard-candidate-photo
    .onboard-photo-text {
    line-height: 16px;
    margin-bottom: 0;
}
#candidate-onboarding
    .candidate-onboard-step
    .onboard-candidate-photo
    .onboard-photo-text
    i {
    color: #1fb799;
}
#candidate-onboarding
    .candidate-onboard-step
    .onboard-candidate-photo
    .profile-image
    .image-button {
    background: rgba(0, 0, 0, 0.4);
    color: #f6f7f9;
    font-size: 11px;
    height: 0;
    line-height: 14px;
    opacity: 50;
    overflow: hidden;
    padding: 0 3px;
    position: absolute;
    right: 0;
    text-align: center;
    transition: all 0.2s ease-in-out;
    width: 100%;
    -webkit-transition: all 0.2s ease-in-out;
}
#candidate-onboarding
    .candidate-onboard-step
    .onboard-candidate-photo
    .profile-image
    .image-button
    i {
    color: white;
}
#candidate-onboarding
    .candidate-onboard-step
    .onboard-candidate-photo
    .profile-image
    .image-button.upload {
    bottom: 0;
}
#candidate-onboarding
    .candidate-onboard-step
    .onboard-candidate-photo
    .profile-image
    .image-button.delete {
    top: 0;
}
#candidate-onboarding
    .candidate-onboard-step
    .onboard-candidate-photo
    .profile-image
    .thumbnail:hover
    .image-button {
    height: 16px;
}
#candidate-onboarding .candidate-onboard-step .input-group-addon {
    min-width: 56px;
}
#candidate-onboarding .candidate-onboard-step .form-error {
    margin: 10px 0 0 !important;
}
#candidate-onboarding .candidate-onboard-step .form-group {
    margin-bottom: 17px;
}
#candidate-onboarding .candidate-onboard-step .onboard-next-container {
    border-top: 1px solid #ededed;
    padding-top: 4px;
}
#candidate-onboarding .candidate-onboard-step .onboard-next-button,
#candidate-onboarding .candidate-onboard-step .onboard-back-button {
    margin-top: 10px;
    min-width: 110px;
}
#candidate-onboarding
    .candidate-onboard-step-three
    .onboard-step-three-buttons {
    padding-bottom: 30px;
}
#candidate-onboarding
    .candidate-onboard-step-three
    #candidate-onboarding-resume-upload
    .fa {
    color: white;
}
#candidate-onboarding
    .candidate-onboard-step-three
    .current-job-title
    .input-group-addon {
    border-right: 0;
    border-left: 0;
}
#candidate-onboarding
    .candidate-onboard-step-three
    #candidate-onboarding-resume-upload.disabled {
    opacity: 0.6;
    pointer-events: none;
}
#candidate-onboarding
    .candidate-onboard-step-three
    #candidate-onboarding-fill-manually {
    background: #dddddd;
    border-color: #d1d1d1;
    color: inherit;
}
#candidate-onboarding
    .candidate-onboard-step-three
    #candidate-onboarding-fill-manually:hover {
    background: #d1d1d1;
    border-color: #bbb;
    color: inherit;
}
#candidate-onboarding
    .candidate-onboard-step-three
    #candidate-onboarding-fill-manually.disabled {
    opacity: 0.6;
    pointer-events: none;
}

/*Start: Upload photo */
#candidate-onboarding figure,
#candidate-onboarding .upload-doc {
    text-align: center;
    max-width: 200px;
    max-height: 200px;
    border: 1px solid #e3e3e3;
    margin: 0 auto 30px;
    padding: 10px;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
}
#candidate-onboarding figure img {
    background: #c2c5ca;
    width: 180px;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
}
#candidate-onboarding .file-upload {
    text-align: center;
}
#candidate-onboarding .file-upload input,
#candidate-onboarding .file-upload input {
    display: none;
}
.thumb {
    border: 1px solid #ccc;
    margin-bottom: 15px;
    padding: 3px;
    text-align: center;
}
.thumb .close {
    background: rgba(0, 0, 0, 0.5) none repeat scroll 0 0;
    border-radius: 3px;
    font-size: 22px;
    height: 32px;
    line-height: 23px;
    right: 20px;
    top: 6px;
    width: 32px;
    z-index: 9;
    opacity: 1;
    color: #ccc;
    cursor: pointer;
    position: absolute;
    text-align: center;
    transition: color 0.5s ease 0s;
    float: none;
    font-weight: normal;
    text-shadow: none;
}
.thumb img {
    height: auto;
    vertical-align: middle;
    width: 100%;
}
#candidate-onboarding .upload-doc {
    min-width: 100%;
    min-height: 250px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 20px 0 0;
    background: #f4f4f4;
    color: #333;
}
#candidate-onboarding .file-upload {
    text-align: center;
}
#candidate-onboarding .upload-doc strong {
    font-weight: normal;
    line-height: 44px;
    font-size: 50px;
}
#candidate-onboarding .upload-doc p {
    font-size: 18px;
    margin: 0;
}
#candidate-onboarding .upload-doc span {
    color: #666;
}
#candidate-onboarding .col-md-6 .text-center p {
    color: #aaa;
    font-size: 12px;
    text-align: right;
}
#candidate-onboarding .col-md-6 label {
    font-size: 14px;
    font-weight: normal;
    width: 100%;
}
#candidate-onboarding .col-md-6 .text-center p span {
    color: #333;
    font-weight: 500;
}
/* #candidate-onboarding div > .row:first-child {
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
    margin-bottom: 15px;
} */
/*End: Upload photo */

/*Start: Sidebar */
#candidate-onboarding .nav-tabs {
    border: 1px solid #ccc;
    background: #fff;
    padding: 0;
}
#candidate-onboarding .nav-tabs > li {
    float: none;
}
#candidate-onboarding .nav-tabs > li > a {
    border: none;
    background: none;
    color: #333;
    border-bottom: 1px solid #ccc;
    margin: 0;
}
#candidate-onboarding .nav-tabs > li.active > a,
#candidate-onboarding .nav-tabs > li > a:hover,
#candidate-onboarding .nav-tabs > li > a:focus {
    color: #000;
}
#candidate-onboarding .nav-tabs > li.active > a {
    font-weight: 600;
}
#candidate-onboarding .tab-pane {
    background-color: #fff;
    border: 1px solid #ededed;
    padding: 4px 30px 14px;
    min-height: 400px;
}
#candidate-onboarding .tab-pane .sheet {
    background: transparent;
    border: none;
    padding: 0;
}
/*End: Sidebar */

.whatsapp-btn-wrapper {
    width: max-content;
    margin: auto;
    border: 1px solid #cac4c4;
    background: #fff;
    border-radius: 5px;
}

.whatsapp-btn-wrapper h3 {
    display: inline-block;
    margin: 0;
    padding: 10px;
    background: #fbd704;
    position: relative;
    font-size: 16px;
    font-weight: 500;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.whatsapp-btn-wrapper h3::after {
    position: absolute;
    content: '';
    border-top: 10px solid #0000;
    border-bottom: 10px solid #0000;
    border-left: 10px solid #fbd704;
    right: -10px;
    top: 11px;
}

.whatsapp-btn-wrapper a {
    font-size: 16px;
    color: #333;
    font-weight: 500;
    padding: 0 15px;
    text-decoration: none;
}

footer {
    padding: 10px;
    text-align: center;
    border-top: 1px solid #ddd;
    font-size: 12px;
    margin-top: 60px;
    background-color: #3a2926;
    color: #886b65;
}

footer a,
footer a:hover {
    color: #886b65;
}

footer .social-icon-link {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    line-height: 30px;
    background-color: #886b65;
    color: #fff;
    display: inline-block;
}

footer .social-icon-link .fa {
    color: #fff;
}

.btn-social {
    position: relative;
    padding-left: 44px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.btn-social > :first-child {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 32px;
    line-height: 34px;
    font-size: 1.6em;
    text-align: center;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
}
.btn-social.btn-lg {
    padding-left: 34px;
}
.btn-social.btn-lg :first-child {
    line-height: 40px;
    width: 45px;
    font-size: 1.8em;
}
.btn-social.btn-sm {
    padding-left: 38px;
}
.btn-social.btn-sm :first-child {
    line-height: 28px;
    width: 28px;
    font-size: 1.4em;
}
.btn-social.btn-xs {
    padding-left: 30px;
}
.btn-social.btn-xs :first-child {
    line-height: 20px;
    width: 20px;
    font-size: 1.2em;
}
.btn-linkedin {
    color: #fff;
    background-color: #007bb6;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-linkedin:hover,
.btn-linkedin:focus,
.btn-linkedin:active,
.btn-linkedin.active,
.open > .dropdown-toggle.btn-linkedin {
    color: #fff;
    background-color: #005983;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-linkedin:active,
.btn-linkedin.active,
.open > .dropdown-toggle.btn-linkedin {
    background-image: none;
}
.btn-linkedin.disabled,
.btn-linkedin[disabled],
fieldset[disabled] .btn-linkedin,
.btn-linkedin.disabled:hover,
.btn-linkedin[disabled]:hover,
fieldset[disabled] .btn-linkedin:hover,
.btn-linkedin.disabled:focus,
.btn-linkedin[disabled]:focus,
fieldset[disabled] .btn-linkedin:focus,
.btn-linkedin.disabled:active,
.btn-linkedin[disabled]:active,
fieldset[disabled] .btn-linkedin:active,
.btn-linkedin.disabled.active,
.btn-linkedin[disabled].active,
fieldset[disabled] .btn-linkedin.active {
    background-color: #007bb6;
    border-color: rgba(0, 0, 0, 0.2);
}
.btn-linkedin .badge {
    color: #007bb6;
    background-color: #fff;
}
.btn-social .fa {
    color: white;
}

.drop-zone {
    padding: 20px;
    margin-bottom: 20px;
    color: #777;
    text-align: center;
    border: 2px dashed #eee;
    border-radius: 4px;
}

.drop-zone .lead {
    margin-bottom: 10px;
    font-weight: 400;
    color: #333;
}

.thumb-image {
    position: relative;
    overflow: hidden;
    padding-bottom: 100%;
}
.thumb-image img {
    position: absolute;
    max-width: 100%;
    top: 0%;
    left: 50%;
    transform: translateX(-50%) translateY(-0%);
}
.profile-download-list {
    background-color: #ffffff;
}
.profile-download-list tbody tr td,
.profile-download-list thead tr th {
    padding: 10px;
}

.glider-slide {
    padding: 4px;
}
