.spinner-container.inline {
  padding: 0; }
  .spinner-container.inline .spinner {
    height: 17px; }

.spinner-container {
  padding: 20px 0;
  margin: 0px auto;
  width: 100%; }

.spinner {
  height: 30px;
  text-align: center;
  font-size: 10px; }

.spinner > div {
  background-color: rgba(166, 210, 165, 0.9);
  height: 100%;
  width: 7px;
  margin: 0 3px 0 0;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out; }

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s; }

.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s; }

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s; }

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s; }

@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4); }
  20% {
    -webkit-transform: scaleY(1); } }

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4); }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1); } }
