/* START: SIDEBAR
================= */
.refine-search {
  background: #fff;
  /*box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);*/
  border-radius: 2px;
  box-shadow: 0 0 5px #e4e5e8;
  border: 1px solid #e4e5e8;
}

.refine-search h4 {
  font-size: 18px;
  position: relative;
  padding: 15px 0 15px 40px;
  margin: 0;
  background: #fbdede;
}

.refine-search h4:before {
  position: absolute;
  content: "";
  top: 15px;
  left: 10px;
  width: 20px;
  height: 20px;
  background: url(../images/base-sprite.png) -36px -35px no-repeat;
}

.refine-search h5 {
  font-size: 15px;
  margin: 0;
  padding: 15px;
  background: #f7f7f7;
  border-bottom: 1px solid #e4e5e8;
  border-top: 1px solid #e4e5e8;
  text-transform: uppercase;
}

.refine-search ul {
  /*max-height:235px;*/
  overflow-y: scroll;
  transition: all 1.5s ease;
  padding: 0 15px;
}

.refine-search .expand ul {
  max-height: 1000px;
}

.refine-search ul li label {
  color: #333;
  font-size: 14px;
  padding-left: 35px;
}

.refine-search ul li label strong {
  color: #999;
  font-weight: normal;
  font-size: 14px;
}

.refine-search .show-more {
  padding-left: 45px;
  color: #0078d6;
  display: block;
  margin-bottom: 30px;
}
/* END: SIDEBAR
================= */

/* START: RESULTS
================= */
.search-results h3 {
  margin: 0 0 10px;
}
.search-results > ul > li {
  border: 1px solid #e4e5e8;
  padding: 10px;
  position: relative;
  box-shadow: 0 0 5px #e4e5e8;
  margin-bottom: 10px;
  background-color: #fff;
}
.search-results > ul > li h4 a {
  margin: 0;
  font-size: 20px;
  color: #cb202d;
}
.search-results > ul > li .media {
  border-bottom: 1px solid #e4e5e8;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.search-results > ul > li .media .pull-left {
  background: #e4e5e8;
  min-width: 155px;
  min-height: 165px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0;
}
.search-results > ul > li .media .pull-left a {
  position: absolute;
  bottom: 0;
  display: block;
  background: #727274;
  font-size: 11px;
  color: #fff;
  width: 100%;
  text-align: center;
  left: 0;
  padding: 5px;
}
.search-results > ul > li .media .media-body {
  padding-left: 20px;
}
.search-results > ul > li .media .media-body .col-sm-6:first-child {
  border-right: 1px solid #e4e5e8;
}
.search-results > ul > li ul li {
  padding: 2px 5px 10px 2px;
}
.search-results > ul > li ul li span,
.search-results > ul > li ul li label {
  min-width: 130px;
  max-width: 160px;
  display: inline-block;
  position: relative;
  font-size: 14px;
  color: #777;
}
.search-results > ul > li ul li label {
  min-width: 80px;
  max-width: 100px;
}

.search-results > ul > li ul li span:last-child {
  font-weight: 600;
  color: #333;
  padding-left: 10px;
}
.search-results > ul > li ul li span:last-child:before {
  position: absolute;
  content: ":";
  top: 0px;
  left: 0px;
}
.search-results > ul > li .shortlist {
  background: #f5f5f5;
  font-size: 12px;
  color: #333;
  border: 1px solid #d3d3d3;
  border-radius: 3px;
  padding: 5px 15px;
}
.search-results > ul > li .shortlist .glyphicon {
  color: #cb202d;
}
.search-results > ul > li a.pull-right {
  background: #cb202d;
  color: #fff;
  display: inline-block;
  padding: 5px 15px;
  border-radius: 3px;
  text-transform: capitalize;
  font-size: 12px;
}
/* END: RESULTS
================= */

/* START: RESPONSIVE
===================== */
@media (min-width: 992px) and (max-width: 1199px) {
  .refine-search ul li label {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .search-results > ul > li ul li span {
    font-size: 12px;
  }

  .search-results > ul > li .media,
  .search-results > ul > li .media-body {
    width: auto;
  }
  .search-results > ul > li .media li {
    display: block;
  }
  .search-results > ul > li ul li span {
    min-width: inherit;
  }
  .search-results > ul > li .media .pull-left {
    min-height: inherit;
    min-width: inherit;
  }
  /*.search-results > ul > li .shortlist .after,
    .search-results > ul > li .shortlist .before {
        display:none !important;
    }*/
  .search-results > ul > li .shortlist {
    display: inline-block;
    padding: 3px 10px;
  }
  .search-results > ul > li .shortlist .glyphicon {
    margin-right: 0;
  }
  .search-results > ul > li .media .pull-left a {
    font-size: 12px;
    padding: 3px;
  }
  .search-results > ul > li h4 {
    font-size: 18px;
  }
  .search-results h3 {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .search-results > ul > li a.pull-right {
    font-size: 12px;
  }
  .refine-search {
    position: absolute;
    top: -15px;
    max-width: 320px;
    left: -340px;
    /*position: fixed;
        bottom:-425px;
        max-height: 300px;
        box-shadow: 0px 2px 10px #484848;
        left:0;*/
    width: 100%;
    z-index: 99;
    overflow: scroll;
    transition: all 0.5s ease;
    background: #fff;
  }

  .filter-open .page-wrap,
  .filter-open .container,
  .filter-open .row,
  .filter-open .col-md-3 {
    height: 100%;
  }
  .filter-open .refine-search {
    left: 0;
    /*bottom:0px;*/
  }
  .filter-open .page-wrap .row {
    margin: 0;
  }
  .filter-open .page-wrap .container {
    padding-left: 0;
    padding-right: 0;
  }
  .filter-open .search-results {
    display: none;
  }
  .filter-open footer {
    display: none;
  }
  .filter-open .navbar {
    display: none;
  }
  .filter-open .refine-search > div:last-child .show-more,
  .filter-open .refine-search > div:last-child ul {
    padding-bottom: 35px;
  }

  /* START: MOBILE RESULTS
    ========================= */
  .container {
    padding: 0 15px;
  }
  .search-results > ul > li .media .media-body {
    padding-left: 10px;
  }
  .search-results > ul > li .media {
    margin-bottom: 0;
    padding: 10px;
  }
  .search-results > ul > li > .clearfix {
    padding: 5px 10px;
    background: #f3f3f3;
  }
  .search-results > ul > li {
    padding: 0;
  }
  .search-results > ul > li .shortlist {
    margin: 0;
    background: transparent;
    border: none;
    font-size: 13px;
  }
  .search-results > ul > li a.pull-right {
    font-size: 11px;
  }
  /* END: MOBILE RESULTS
    ========================= */
}
